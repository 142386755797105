<template>
  <fragment>
    <tr>
      <td>
        <span :style="{paddingLeft: `${tab}px` }">{{ trigger.label }}</span>
      </td>
      <td
        v-for="perm in [viewPermission, changePermission]"
        :key="'perm' + (perm ? perm.id: trigger.id)"
        class="trigger-table-td"
      >
        <div
          v-if="perm"
          class="custom-control custom-checkbox text-center p-0"
        >
          <input
            :id="'item_' + perm.id"
            type="checkbox"
            :value="trigger.triggers.find(item => item.label === perm.label).id"
            :disabled="isEditable"
            :checked="perm.checked"
            class="custom-control-input children-checkbox"
            hidden
            @click="editChildrenCheckbox($event, trigger, perm.label)"
          >
          <label
            :for="'item_' + perm.id"
            :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
          />
        </div>

      </td>
    </tr>
    <trigger-table-component
      v-for="perm in trigger.children"
      :key="perm.id"
      :trigger="perm"
      :is-editable="isEditable"
      :tab="tab + 30"
      @addPermissions="editChildrenCheckbox"
    />
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
// eslint-disable-next-line import/no-self-import
import TriggerTableComponent from './TriggerTableComponent.vue'

export default {
  name: 'TriggerTableComponent',
  components: {
    TriggerTableComponent,
    Fragment,
  },
  props: {
    trigger: {
      type: Object,
      required: true,
    },
    tab: {
      type: Number,
      default: 0,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addTriggers'],
  data() {
    return {
      triggers: [],
    }
  },
  computed: {
    viewPermission() {
      return this.trigger.triggers.find(perm => perm.label === 'visual')
    },
    changePermission() {
      return this.trigger.triggers.find(perm => perm.label === 'audio')
    },
  },
  methods: {
    editChildrenCheckbox(event, trigger, checkboxLabel) {
      this.$emit('addTriggers', event, trigger, checkboxLabel)
    },
  },
}
</script>

<style scoped>
  .trigger-table-td {
    width: 100px;
  }
  .check--disabled {
    background-color: #efefef;
    border-radius: 5px;
    opacity: 0.5;
  }
</style>
