<template>
  <div>
    <div class="d-flex justify-content-end">

      <feather-icon
        v-if="notification.id && organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        class="cursor-pointer"
        :icon="isEditable ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        @click="changeEditable"
      />

    </div>
    <b-form
      @submit.prevent="submit"
    >
      <validation-observer
        ref="notificationValidationRef"
      >
        <b-row>
          <b-col md="12">
            <div class="row">
              <b-col md="6">
                <b-form-group
                  label-class="inputLabel ml-1 mb-0"
                  label-cols-md="0"
                  label-align-md="left"
                  :label="$t('Name')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Notification Name')"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      v-model="notification.label"
                      class="col"
                      :disabled="isEditable"
                      placeholder="Type..."
                      @input="isLabelOrStatusChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <span class="font-weight-bolder ml-3"> {{ $t('Status') }}</span>
                <b-form-group
                  class="d-flex justify-content-between m-0 p-0"
                  label-cols-md="0"
                  label-align-md="left"
                  label-for="status"
                >
                  <div class="d-flex align-items-end ml-1">
                    <b-form-checkbox
                      v-model="notification.is_active"
                      name="status"
                      style="padding: 10px"
                      :class="`${isEditable ? 'check--disabled': ''}`"
                      :disabled="isEditable"
                      @input="isLabelOrStatusChange()"
                    />
                    <span class="ml-1">{{ $t('Active') }}</span>
                  </div>
                </b-form-group>
              </b-col>
            </div>
            <div class="pb-3">
              <!--              <h3 class="mt-1 font-weight-bolder">-->
              <!--                Notifications-->
              <!--              </h3>-->
            </div>
            <div
              v-for="permission in notificationList"
              :key="permission.id"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span class="font-weight-bolder font-medium-5 ml-1">
                  {{ permission.label }}
                </span>
                <div class="d-flex align-items-center">
                  <span />
                  <span
                    class="text-center flex-column align-items-center"
                    style="margin-right: 42px"
                  >
                    {{ $t('Visual') }}
                    <span
                      class="custom-control custom-checkbox text-center p-0"
                    >
                      <input
                        :id="'view_' + permission.id"
                        type="checkbox"
                        :value="permission.triggers.find(item => item.label === 'visual').id"
                        :disabled="isEditable"
                        :checked="checkboxStatus(permission, 'visual')"
                        hidden
                        class="custom-checkbox custom-control main-checkbox"
                        @click="editMainCheckboxTriggers($event, permission, 'visual')"
                      >
                      <label
                        :for="'view_' + permission.id"
                        :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                      />
                    </span>
                  </span>
                  <span
                    class="text-center d-flex flex-column align-items-center"
                    style="margin-right: 37px"
                  >
                    {{ $t('Audio') }}
                    <span
                      class="custom-control custom-checkbox text-center p-0"
                    >
                      <input
                        :id="'change_' + permission.id"
                        type="checkbox"
                        :value="permission.triggers.find(item => item.label === 'audio').id"
                        :disabled="isEditable"
                        hidden
                        :checked="checkboxStatus(permission, 'audio')"
                        class="custom-checkbox custom-control main-checkbox"
                        @click="editMainCheckboxTriggers($event, permission, 'audio')"
                      >
                      <label
                        :for="'change_' + permission.id"
                        :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div
                v-for="permissionSecond in permission.children"
                :key="permissionSecond.label"
              >
                <div class="d-flex align-items-center justify-content-between w-100 my-1">
                  <span
                    v-b-toggle="'group' + permissionSecond.id"
                    class="font-weight-bolder ml-1"
                  >
                    {{ permissionSecond.label }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      class="when-opened"
                    />
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="when-closed"
                    />
                  </span>
                  <div class="d-flex align-items-center">
                    <span />
                    <span class="text-center flex-column align-items-center mr-5">
                      <span
                        class="custom-control custom-checkbox text-center p-0"
                        style="margin-right: 18px"
                      >
                        <input
                          :id="'View_' + permissionSecond.id"
                          type="checkbox"
                          :value="permissionSecond.triggers.find(item => item.label === 'visual').id"
                          :disabled="isEditable"
                          :checked="checkboxStatus(permissionSecond, 'visual')"
                          hidden
                          class="custom-checkbox custom-control second-checkbox"
                          @click="editMainCheckboxTriggers($event, permissionSecond, 'visual')"
                        >
                        <label
                          :for="'View_' + permissionSecond.id"
                          :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                        />
                      </span>
                    </span>
                    <span
                      class="text-center d-flex flex-column align-items-center"
                      style="margin-right: 30px"
                    >
                      <span
                        class="custom-control custom-checkbox text-center p-0"
                        style="margin-right: 15px"
                      >
                        <input
                          :id="'Change_' + permissionSecond.id"
                          type="checkbox"
                          :value="permissionSecond.triggers.find(item => item.label === 'audio').id"
                          :disabled="isEditable"
                          hidden
                          :checked="checkboxStatus(permissionSecond, 'audio')"
                          class="custom-checkbox custom-control second-checkbox"
                          @click="editMainCheckboxTriggers($event, permissionSecond, 'audio')"
                        >
                        <label
                          :for="'Change_' + permissionSecond.id"
                          :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <b-collapse
                  :id="'group' + permissionSecond.id"
                  :visible="Boolean(permissionSecond.children.length)"
                >
                  <div class="table-responsive position-relative">
                    <table
                      v-if="permission.children.length > 0"
                      class="table b-table table-striped"
                    >
                      <tbody>
                        <permission-table-component
                          v-for="perm in permissionSecond.children"
                          :key="perm.id"
                          :is-editable="isEditable"
                          :trigger="perm"
                          @addTriggers="editMainCheckboxTriggers"
                        />
                      </tbody>
                    </table>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-col>
        </b-row>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-users-notifications-list' })"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup,
  BButton, BFormInput, BFormCheckbox,
  BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import PermissionTableComponent from './TriggerTableComponent.vue'

export default {
  name: 'FormComponent',
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BCollapse,
    PermissionTableComponent,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      triggers: this.$props.notification?.triggers ? [...this.$props.notification?.triggers] : [],
      storeTriggersList: this.$store.state.listModule?.permissionList,
      propsNotificationTriggerIds: this.$props.notification?.triggers ?? [],
      storeTriggersIds: [],
      isSelectedAll: false,
      viewCheckboxIds: [],
      isEditable: store.state.app.organizationRequiresEditConfirmation,
      iterationTrigger: 0,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    notificationList() {
      // eslint-disable-next-line no-unused-expressions
      const markChecked = triggerGroup => {
        triggerGroup.forEach(trigger => {
          // eslint-disable-next-line array-callback-return
          trigger.triggers.filter(item => {
            this.storeTriggersIds.push(item.id)
            if (this.propsNotificationTriggerIds.includes(item.id)) {
              // eslint-disable-next-line no-param-reassign
              item.checked = true
            }
          })
          if (trigger.children.length > 0) {
            markChecked(trigger.children)
          }
        })
      }
      markChecked(this.$store.state.listModule?.notificationList)
      // console.log(this.$store.state.listModule.notificationList)
      return this.$store.state.listModule.notificationList
    },
    notificationForm() {
      return this.$store.state[this.MODULE_NAME].notificationForm
    },
    notificationClone() {
      return this.$store.state.cloneData.notification
    },
    isFormChanged() {
      this.iterationTrigger
      // if (!this.notification?.id) return
      const parsedClone = JSON.parse(this.notificationClone)
      if (!this.notification?.id) {
        if (this.notification?.label !== undefined && this.notification?.label !== '') {
          return true
        }

        if (this.notification?.is_active !== undefined && this.notification?.is_active !== false) {
          return true
        }
        if (this.triggers.length > 0) {
          return true
        }

        return false
      }
      if (!Array.isArray(this.triggers) || !Array.isArray(parsedClone.triggers)) {
        // eslint-disable-next-line consistent-return
        return null
      }
      if (this.triggers.length !== parsedClone.triggers.length
          || !this.checker(this.triggers, parsedClone.triggers)
      ) {
        // eslint-disable-next-line consistent-return
        return true
      }
      if (this.notification?.label !== parsedClone.label) {
        // eslint-disable-next-line consistent-return
        return true
      }
      if (this.notification?.is_active !== parsedClone.is_active) {
        // eslint-disable-next-line consistent-return
        return true
      }
      // eslint-disable-next-line consistent-return
      return false
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  mounted() {
    if (!this.notification.id) this.isEditable = false
  },
  methods: {
    isLabelOrStatusChange() {
      this.iterationTrigger += 1
    },
    resetTriggersToInputDefault(triggerList, defaultValue = undefined) {
      triggerList.forEach(i => {
        if (i.children.length > 0) {
          this.resetTriggersToInputDefault(i.children, defaultValue)
        }
        i.triggers.forEach(c => {
          if (document.getElementById(`item_${c.id}`)) {
            document.getElementById(`item_${c.id}`).checked = defaultValue === undefined ? JSON.parse(this.notificationClone).triggers.includes(Number(document.getElementById(`item_${c.id}`).value)) : defaultValue
            return true
          }
          if (document.getElementById(`View_${c.group_id}`)) {
            document.getElementById(`View_${c.group_id}`).checked = defaultValue === undefined ? JSON.parse(this.notificationClone).triggers.includes(Number(document.getElementById(`View_${c.group_id}`).value)) : defaultValue
          }
          if (document.getElementById(`Change_${c.group_id}`)) {
            document.getElementById(`Change_${c.group_id}`).checked = defaultValue === undefined ? JSON.parse(this.notificationClone).triggers.includes(Number(document.getElementById(`Change_${c.group_id}`).value)) : defaultValue
          }
        })
      })
    },
    resetTriggersToDefault() {
      if (!this.notification?.id) {
        this.resetTriggersToInputDefault(this.notificationList, false)
      } else {
        this.resetTriggersToInputDefault(this.notificationList)
      }
    },
    checkboxStatus(notification, checkboxLabel) {
      return notification.triggers.find(item => item.label === checkboxLabel).checked
    },
    editMainCheckboxTriggers(event, notification, checkboxLabel) {
      const firstCheckbox = document.getElementsByClassName('main-checkbox')
      const secondCheckbox = document.getElementsByClassName('second-checkbox')
      const thirdCheckbox = document.getElementsByClassName('children-checkbox')
      const permArr = []
      permArr.push(notification)
      const permissionsIdArr = []
      const getChildrenId = permissionGroup => {
        // eslint-disable-next-line no-restricted-syntax
        permissionGroup.forEach(permItem => {
          const permLabel = permItem.triggers.find(item => item.label === checkboxLabel)
          const viewLabel = permItem.triggers.find(item => item.label === 'visual')
          if (typeof permLabel === 'object' && permLabel !== null) {
            permissionsIdArr.push(permLabel.id)
            if (checkboxLabel === 'audio' && event.target.checked === true) {
              permissionsIdArr.push(viewLabel.id)
            }
          }
          if (permItem.children.length > 0) {
            getChildrenId(permItem.children)
          }
        })
        return permissionsIdArr
      }
      getChildrenId(permArr)
      if (event.target.checked) {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.triggers.push(Number(element.value))
            element.checked = true
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.triggers.push(Number(element.value))
            element.checked = true
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.triggers.push(Number(element.value))
            element.checked = true
          }
        }
      } else if (checkboxLabel === 'visual' && event.target.checked === false) {
        const toRemove = []
        // eslint-disable-next-line no-shadow
        const getChildrenId = permissionGroup => {
          // eslint-disable-next-line no-restricted-syntax
          permissionGroup.forEach(permItem => {
            const changeLabel = permItem.triggers.find(item => item.label === 'audio')
            if (typeof changeLabel === 'object' && changeLabel !== null) {
              permissionsIdArr.push(changeLabel.id)
            }
            if (permItem.children.length > 0) {
              getChildrenId(permItem.children)
            }
          })
          return permissionsIdArr
        }
        getChildrenId(permArr)
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          // this.permissions = this.permissions.filter(item => item !== Number(element.value))
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        this.triggers = this.triggers.filter(el => !toRemove.includes(el))
      } else {
        const toRemove = []
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        this.triggers = this.triggers.filter(el => !toRemove.includes(el))
      }

      this.triggers = [...new Set(this.triggers)]
    },
    changeEditable() {
      this.isEditable = !this.isEditable
    },
    loader() {
      this.$refs.notificationValidationRef.reset()
      if (!this.notification?.id) {
        this.$store.commit('notification/GET', { is_active: false })
        this.triggers = []
        this.resetTriggersToDefault()
      } else {
        this.triggers = JSON.parse(this.notificationClone).triggers
        this.$store.commit('notification/SET_FORM', JSON.parse(this.notificationClone))
        // this.$router.push({ name: 'settings-users-notifications' }).catch(() => {})
        this.resetTriggersToDefault()
        this.notification.label = JSON.parse(this.notificationClone).label
        this.notification.is_active = JSON.parse(this.notificationClone).is_active
      }
    },
    resetTriggers() {
      if (this.propsNotificationTriggerIds) {
        // eslint-disable-next-line array-callback-return
        this.storeTriggersIds.filter(storeTriggersId => {
          if (this.propsNotificationTriggerIds.includes(storeTriggersId)) {
            // eslint-disable-next-line no-unused-expressions
            this.$store.state.listModule?.notificationList.forEach(notification => {
              // eslint-disable-next-line array-callback-return
              notification.triggers.filter(item => {
                // eslint-disable-next-line no-param-reassign
                item.checked = false
              })
            })
          }
        })
      }
    },
    submit() {
      if (typeof this.notification.is_active === 'undefined') {
        this.notification.is_active = false
      }
      this.notification.trigger_ids = [...new Set(this.triggers)]
      this.$refs.notificationValidationRef.validate()
        .then(success => {
          if (success) {
            this.$refs.notificationValidationRef.reset()
            this.$emit('submit', this.notification, this.resetTriggers)
          } else {
            scrollToError(this, this.$refs.notificationValidationRef)
          }
        })
    },
    cancel() {
      this.$refs.notificationValidationRef.reset()
      this.$emit('cancel')
      this.resetTriggers()
    },
    checker(arr, target) {
      return target.every(v => arr.includes(v))
    },
  },
  setup() {
    const MODULE_NAME = 'notification'
    const MODULE_NAME_CLONE = 'cloneData'
    // const isEditable = ref(true)

    return {
      // isEditable,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.custom-checkbox {
  height: 15px;
  width: 15px;
  margin-left: 10px;
}
.custom-control-label::after {
  background-color: #efefef;
  border-radius: 2px;
  opacity: 0.5;
  width: 1px;
  height: 1px;
}
.check--disabled {
  background-color: #efefef;
  border-radius: 5px;
  opacity: 0.5;
}
</style>
