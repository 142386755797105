<template>
  <div>
    <b-form>
      <b-row>
        <b-col md="12">
          <div class="row">
            <b-col md="6">
              <b-form-group
                label-class="inputLabel"
                label-cols-md="3"
                label-align-md="left"
                :label="$t('Name')"
                label-for="name"
              >
                <b-skeleton
                  height="30px"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="d-flex justify-content-between"
                label-class="inputLabel"
                label-cols-md="3"
                label-align-md="left"
                :label="$t('Active')"
                label-for="status"
              >
                <b-skeleton
                  width="30px"
                  height="30px"
                />
              </b-form-group>
            </b-col>
          </div>
          <div>
            <h3 class="mt-1 font-weight-bolder">
              Notifications
            </h3>
          </div>
          <div
            v-for="(permission) in 2"
            :key="permission"
          >

            <b-collapse
              :id="String(permission)"
              visible
            >
              <div class="table-responsive position-relative">
                <table
                  class="table b-table table-striped"
                >
                  <thead>
                    <tr class="d-flex justify-content-end">
                      <th />
                      <th class="text-center">
                        {{ $t('Visual') }}
                      </th>
                      <th class="text-center">
                        {{ $t('Audio') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <b-skeleton
                      v-for="perm in 20"
                      :key="perm"
                      width="100%"
                      height="50px"
                    />
                  </tbody>
                </table>
              </div>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup,
  BCollapse, BSkeleton,
} from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BCollapse,
    BSkeleton,
  },
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.custom-control-label::after {
  background-color: #efefef;
  border-radius: 2px;
  opacity: 0.5;
  width: 5px;
  height: 5px;
}
</style>
